<template>
  <section id="chat-status-filter">
    <label for="bot-filter">
      {{
        multipleSelect
          ? lang.metrics.filters.statuses.label[languageSelected]
          : lang.metrics.filters.status.label[languageSelected]
      }}
    </label>

    <multiselect
      :key="`multiselect-${key.getTime()}`"
      v-model="value"
      tag-placeholder=""
      :placeholder="lang.metrics.filters.status.placeholder[languageSelected]"
      label="name"
      track-by="code"
      :options="options"
      :multiple="multipleSelect"
      :taggable="true"
      :limit="1"
      :limit-text="limitText"
      :show-labels="false"
      @input="change($event)"
    />

    <vs-button
      color="primary"
      type="flat"
      size="small"
      @click="clear"
      class="reset-button mt-1"
      >{{ lang.metrics.filters.range.clear[languageSelected] }}</vs-button
    >
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'StatusFilter',
  props: {
    options: Array,
    multipleSelect: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      key: new Date(),
      value: []
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    limitText(count) {
      return `+${count}`
    },
    change(event, type = 'change') {
      let value
      if (this.multipleSelect) {
        value = this.value.length ? this.value : this.options

        this.$emit(type, {
          type: 'status',
          value: value.map(v => v.code)
        })
      } else {
        value = this.value

        this.$emit(type, {
          type: 'status',
          value: value ? [value.code] : null
        })
      }
    },
    reset() {
      this.value = []
    },
    clear() {
      this.reset()
      this.change()
    }
  },
  mounted() {
    this.change(null, 'init')
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
#chat-status-filter {
  display: flex;
  flex-direction: column;

  label {
    display: block;
  }
  .vs-input--input {
    min-height: 43px;
  }

  .reset-button {
    align-self: flex-end;
  }
}
</style>
